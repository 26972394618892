import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { client } from '../api';
import type { AppealReviewSummary, AppealReviewsResponse } from '../api';
import PageContainer from '../components/PageContainer';
import AppealReviewsTable from '../components/appealReviews/AppealReviewsTable';
import { Outlet } from 'react-router-dom';
import { appealReviewsQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { formatStandardDateLocaleString } from '../utils';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

const AppealReviewsTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery<AppealReviewsResponse>({
    queryKey: appealReviewsQueryKey(),
    queryFn: () =>
      client.get('/appeal-reviews').then((res) => {
        res.data.appeal_reviews = res.data.appeal_reviews.map((item: AppealReviewSummary) => ({
          ...item,
          // We format dates here instead of in TasksTable so that the global search function applies to the formatted data
          created_at: formatStandardDateLocaleString(item.created_at),
          prescription_recieved_at: formatStandardDateLocaleString(item.prescription_recieved_at),
        }));
        return res.data;
      }),
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = <PageLoadError />;
  } else if (data) {
    body = <AppealReviewsTable data={data.appeal_reviews} />;
  }

  return (
    <>
      <PageContainer title="Reviews">{body}</PageContainer>
      <Outlet />
    </>
  );
};

export default AppealReviewsTablePage;

import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { Hairline } from '../tableItemDetailViewComponents/Containers';
import React from 'react';
import { emptyTableCellCopy } from 'design-system/src/sharedCopy';

const StyledExtraInfoTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldLabel = styled(Typography).attrs({ styledAs: 'bodySmallSpaceGrotesk' })`
  color: var(--dark-gray);
  min-width: 120px;
  padding-right: 1rem;
`;

export type ExtraInfoTableProps = {
  data: Array<{ label: string; value: string | string[] | null }>;
};

/**
 * Table for displaying extra content at the top of the page.
 * Pass through a list to ensure the ordering of rows.
 */
export const ExtraInfoTable = ({ data }: ExtraInfoTableProps) => {
  return (
    <div>
      <StyledExtraInfoTable>
        {data.map((item) => {
          // Default to empty table cell copy (e.g. '-') if value is null
          let value: string | React.ReactNode[] = emptyTableCellCopy;

          if (typeof item.value === 'string') {
            value = item.value;
          } else if (Array.isArray(item.value)) {
            value = item.value.map((valueItem) => {
              return <div key={`styled-extra-info-table-${valueItem}`}>{valueItem}</div>;
            });
          }

          return (
            <FieldGroup key={item.label}>
              <FieldLabel>{item.label}</FieldLabel>
              <Typography renderedAs="div">{value}</Typography>
            </FieldGroup>
          );
        })}
      </StyledExtraInfoTable>

      <Hairline />
    </div>
  );
};

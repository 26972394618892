import React, { useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import PageContainer from '../components/PageContainer';
import RxTable from '../components/rx/RxTable';
import { useFetchPrescriptions } from '../components/rx/useFetchPrescriptions';
import { useFetchRxTableFilters } from '../components/rx/useFetchRxTableFilters';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { useSearchParams, Navigate } from 'react-router-dom';
import { RxFilterValues } from '../types';
import { downloadPrescriptionsTable } from '../components/rx/downloadPrescriptionsTable';
import { Toast } from 'design-system/src/components/Toast/Toast';
import toast from 'react-hot-toast';
import { OverallStatusFilter } from '../api';
import { MULTI_SELECT_DELINEATOR } from '../constants';

const RESULTS_PER_PAGE = 25;

const filterValuesFromSearchParams = (searchParams: URLSearchParams) => {
  return {
    prescriber: searchParams.get('prescriber') || '',
    office: searchParams.get('office') || '',
    drug: searchParams.get('drug_name') || '',
    biologics: searchParams.get('biologics') === 'true' || false,
    overallStatus: searchParams.get('overall_status') || OverallStatusFilter.All,
    awaitingOffice: searchParams.get('awaiting_office') === 'true' || false,
    awaitingPatient: searchParams.get('awaiting_patient') === 'true' || false,
  } as RxFilterValues;
};

const RxTablePage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPageIndex = searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0;
  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const [search, setSearch] = useState(searchParams.get('search') || '');

  // used to fetch serverside filtered data
  const [filterValues, setFilterValues] = useState<RxFilterValues>(filterValuesFromSearchParams(searchParams));
  const [total, setTotal] = useState(0);

  const authContext = React.useContext(AuthContext);
  if (!authContext.user?.tabs.includes('prescriptions')) {
    // The / route shows the prescriptions page, but a few edge case orgs have this tab disabled
    // and need to be redirected to the analytics page
    return <Navigate to="/analytics" replace />;
  }

  const { data, isLoading, error } = useFetchPrescriptions(pageIndex, RESULTS_PER_PAGE, search, filterValues);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleDownload = async () => {
    try {
      toast.custom(() => <Toast variant="default">Download started! Please keep this window open.</Toast>);
      setDownloadLoading(true);
      const downloadData = await downloadPrescriptionsTable(pageIndex, RESULTS_PER_PAGE, search, filterValues);
      if (!downloadData) return;

      const blob = new Blob([downloadData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `prescriptions_${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.custom(() => <Toast variant="success">Download complete.</Toast>);
    } catch (error) {
      toast.custom(() => <Toast variant="error">Download failed.</Toast>);
    } finally {
      setDownloadLoading(false);
    }
  };

  const {
    data: filterOptionsData,
    isLoading: filterOptionsLoading,
    error: filterOptionsError,
  } = useFetchRxTableFilters();

  useEffect(() => {
    if (data) {
      setTotal(data.total);
    }
  }, [data]);

  const setPrescriberFilter = (prescriberNpisList: string[]) => {
    const prescriberNpisString = prescriberNpisList.join(MULTI_SELECT_DELINEATOR);
    setFilterValues({ ...filterValues, prescriber: prescriberNpisString });
    updateFilterOrSearchValues({
      prescriber: prescriberNpisString,
    });
  };

  const setOfficeFilter = (officeNamesList: string[]) => {
    const officeNamesString = officeNamesList.join(MULTI_SELECT_DELINEATOR);
    setFilterValues({ ...filterValues, office: officeNamesString });
    updateFilterOrSearchValues({
      office: officeNamesString,
    });
  };

  const setDrugNameFilter = (drugNamesList: string[]) => {
    const drugNamesString = drugNamesList.join(MULTI_SELECT_DELINEATOR);
    setFilterValues({ ...filterValues, drug: drugNamesString });
    updateFilterOrSearchValues({ drug_name: drugNamesString });
  };

  const setBiologicsFilter = (biologics: boolean) => {
    setFilterValues({ ...filterValues, biologics });
    if (biologics) {
      updateFilterOrSearchValues({ biologics: 'true' });
    } else {
      updateFilterOrSearchValues({ biologics: undefined });
    }
  };

  const setAwaitingOfficeFilter = (awaitingOffice: boolean) => {
    setFilterValues({ ...filterValues, awaitingOffice });
    updateFilterOrSearchValues({ awaiting_office: awaitingOffice ? 'true' : undefined });
  };

  const setAwaitingPatientFilter = (awaitingPatient: boolean) => {
    setFilterValues({ ...filterValues, awaitingPatient: awaitingPatient });
    updateFilterOrSearchValues({ awaiting_patient: awaitingPatient ? 'true' : undefined });
  };

  const setOverallStatusFilter = (overallStatus: OverallStatusFilter) => {
    if (overallStatus === OverallStatusFilter.Completed) {
      updateFilterOrSearchValues({
        overall_status: overallStatus,
        awaiting_office: undefined,
        awaiting_patient: undefined,
      });
    } else {
      updateFilterOrSearchValues({ overall_status: overallStatus });
    }
    setFilterValues({ ...filterValues, overallStatus, awaitingOffice: false, awaitingPatient: false });
  };

  // updates the url
  const updateFilterOrSearchValues = (keyValues: Record<string, string | undefined>) => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(keyValues).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
    });
    newParams.delete('page');
    setSearchParams(newParams);
    setPageIndex(0);
  };

  const goBack = () => {
    const newPageIndex = pageIndex - 1;
    setPageIndex(newPageIndex);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(newPageIndex + 1));
    setSearchParams(newParams);
  };

  const goForward = () => {
    const newPageIndex = pageIndex + 1;
    setPageIndex(newPageIndex);
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(newPageIndex + 1));
    setSearchParams(newParams);
  };

  const submitSearchValue = (searchValue: string) => {
    setSearch(searchValue);
    updateFilterOrSearchValues({ search: searchValue });
  };

  let body;
  if (filterOptionsLoading) {
    body = <LoadingSpinner />;
  } else if (error || filterOptionsError || !filterOptionsData) {
    body = <PageLoadError />;
  } else {
    body = (
      <RxTable
        data={data?.results || []}
        loading={isLoading}
        tableProps={{
          total,
          pageIndex,
          perPage: RESULTS_PER_PAGE,
          submitSearchValue,
          goBack,
          goForward,
          filters: filterOptionsData,
          setPrescriberFilter,
          setOfficeFilter,
          setDrugNameFilter,
          setBiologicsFilter,
          setAwaitingOfficeFilter,
          setAwaitingPatientFilter,
          setOverallStatusFilter,
        }}
        initialSearchValue={search}
        initialFilterValues={filterValues}
        handleDownload={handleDownload}
        downloadLoading={downloadLoading}
      />
    );
  }

  return <PageContainer title="Prescriptions">{body}</PageContainer>;
};

export default RxTablePage;

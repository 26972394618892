import React, { useState, useMemo } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table';

import { useNavigate } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
} from '../Table';
import moment from 'moment';
import { noResultsCopy } from 'design-system/src/sharedCopy';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { PriorAuthTableRow } from '../../api';
import { TableMultiselectFilter } from '../TableDropdownFilter/TableDropdownFilter';
import { type MultiselectAutocompleteOption } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import { sortOfficeNames, stringsToDropdownOptions } from '../../utils';
import styled from 'styled-components';
import { Tooltip, defaultTooltipIcon } from 'design-system/src/components/Tooltip/Tooltip';
import { Checkbox } from '@mui/material';
import { focusVisibleStyles } from 'design-system/src/sharedStyles';
const columnHelper = createColumnHelper<PriorAuthTableRow>();

interface PriorAuthTableProps {
  data: PriorAuthTableRow[];
  onDismiss: (priorAuthId: string) => void;
  onRestore: (priorAuthId: string) => void;
}

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const OuterTableContainer = styled.div`
  overflow-y: visible;
  height: 100%;
`;

const FilterBarRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const DismissalButton = styled.a`
  color: var(--dark-gray);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0);
  transition: text-decoration-color 0.3s;
  &:hover {
    text-decoration-color: var(--dark-gray);
  }
  ${focusVisibleStyles}
`;

const DismissedRow = styled(ClickableRow)`
  opacity: 0.5;
  transition: opacity 0.3s;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const checkboxStyling = {
  color: 'var(--purple)',
  '&.Mui-checked': {
    color: `var(--purple)`,
  },
};

const priorAuthTableMultiSelectFilter = (row: Row<PriorAuthTableRow>, columnId: string, filterValue: string[]) => {
  if (!filterValue.length) {
    // No filters are applied; return all rows
    return true;
  }

  // @ts-expect-error columnId will be one of the column heading keys
  return filterValue.includes(row.original[columnId]);
};

// prescriber column needs a special filter function because it's in a nested
// object
const prescriberMultiSelectFilter = (row: Row<PriorAuthTableRow>, _: string, filterValue: string[]) => {
  if (!filterValue.length) {
    // No filters are applied; return all rows
    return true;
  }

  return filterValue.includes(row.original.rx.prescriber_name || '');
};

const DismissCell: React.FC<{
  dismissed: boolean;
  onDismiss: (priorAuthId: string) => void;
  onRestore: (priorAuthId: string) => void;
  priorAuthId: string;
}> = ({ dismissed, onDismiss, onRestore, priorAuthId }) => {
  if (!dismissed)
    return (
      <DismissalButton
        onClick={(e) => {
          e.stopPropagation();
          onDismiss(priorAuthId);
        }}
      >
        Dismiss
      </DismissalButton>
    );
  else
    return (
      <DismissalButton
        onClick={(e) => {
          e.stopPropagation();
          onRestore(priorAuthId);
        }}
      >
        Restore
      </DismissalButton>
    );
};

const PriorAuthTable: React.FC<PriorAuthTableProps> = ({ data, onDismiss, onRestore }) => {
  const navigate = useNavigate();
  const [showDismissed, setShowDismissed] = useState(false);

  const officeNameStrings: string[] = [];
  const prescriberNameStrings: string[] = [];
  data.forEach((item) => {
    if (item.office_name) {
      officeNameStrings.push(item.office_name);
    }
    prescriberNameStrings.push(item.rx.prescriber_name);
  });

  const filteredData = useMemo(() => {
    return showDismissed ? data : data.filter((row) => !row.pa.dismissed);
  }, [data, showDismissed]);

  const columns = [
    columnHelper.accessor('formatted_prescription_recieved_at', {
      header: 'Prescribed on',
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
    }),
    columnHelper.accessor('rx.prescriber_name', {
      header: 'Prescriber',
      id: 'prescriber_name',
      filterFn: prescriberMultiSelectFilter,
    }),
    columnHelper.accessor('rx.patient_name', {
      header: 'Patient',
    }),
    columnHelper.accessor('formatted_dob', {
      header: 'DOB',
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
      filterFn: priorAuthTableMultiSelectFilter,
    }),
    columnHelper.accessor('formatted_approval_until', {
      header: 'PA expires on',
      enableGlobalFilter: false,
      cell: (info) => (info.getValue() ? moment(info.getValue()).format('MM/DD/YYYY') : ''),
    }),
    columnHelper.accessor('pa.dismissed', {
      header: '',
      enableGlobalFilter: false,
      cell: (info) => (
        <DismissCell
          dismissed={info.getValue()}
          onDismiss={onDismiss}
          onRestore={onRestore}
          priorAuthId={info.row.original.pa.id}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { office_name: false } },
  });

  return (
    <OuterTableContainer>
      <SubtitleContainer>
        <Typography styledAs="bodySmallSpaceGrotesk" weight={500}>
          Prior authorizations expiring in the next 30 days
        </Typography>
        <Tooltip
          content="Note: This list may be missing a small percentage of prior authorizations for which Tandem does not have an expiration date on file."
          childNotInteractive={true}
        >
          {defaultTooltipIcon}
        </Tooltip>
      </SubtitleContainer>
      <TableContainer>
        <FilterBar>
          <FilterBarRow>
            <SearchInput
              label="Search by drug or patient information"
              hideLabel={true}
              onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
              $placement="left"
              $width="20rem"
            />
            <CheckboxContainer>
              <Checkbox
                sx={checkboxStyling}
                checked={showDismissed}
                onChange={(e) => setShowDismissed(e.target.checked)}
                aria-labelledby="dismiss-checkbox-label"
              />
              <Typography id="dismiss-checkbox-label">Show all</Typography>
            </CheckboxContainer>
            <TableMultiselectFilter
              options={stringsToDropdownOptions(officeNameStrings)}
              label={'Office'}
              onChange={(_, value: MultiselectAutocompleteOption[]) => {
                const selectedValuesList = value.map((valueItem) => valueItem.value);
                table.getColumn('office_name')!.setFilterValue(selectedValuesList);
              }}
              sortFn={sortOfficeNames}
              horizontalAlign="right"
            />
            <TableMultiselectFilter
              options={stringsToDropdownOptions(prescriberNameStrings)}
              label={'Prescriber'}
              onChange={(_, value: MultiselectAutocompleteOption[]) => {
                const selectedValuesList = value.map((valueItem) => valueItem.value);
                table.getColumn('prescriber_name')!.setFilterValue(selectedValuesList);
              }}
              horizontalAlign="right"
            />
          </FilterBarRow>
        </FilterBar>
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHeader
                      id={header.id}
                      key={header.id}
                      onClick={() => header.column.toggleSorting()}
                      style={{ width: '150px' }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      <HeaderSortingIndicator
                        isSorted={header.column.getIsSorted()}
                        sortDirection={header.column.getNextSortingOrder()}
                      />
                    </TableHeader>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const RowComponent = row.original.pa.dismissed ? DismissedRow : ClickableRow;
                return (
                  <RowComponent key={row.id} onClick={() => navigate(`/rxs/${row.original.rx.id}`)}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </RowComponent>
                );
              })
            ) : (
              <tr>
                <TableCell colSpan={columns.length}>{noResultsCopy}</TableCell>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </OuterTableContainer>
  );
};

export default PriorAuthTable;

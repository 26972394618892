import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { client, url } from '../../api';
import { PDFViewer } from '../PDFViewer';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import {
  Form,
  FormOverlay,
  FormOverlayLoadingSpinner,
  ResponseInput,
  ButtonRow,
} from '../tableItemDetailViewComponents/FormComponents';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { AppealReviewContext } from './contexts';
import { Button } from '../Button/Button';
import { Typography } from 'design-system/src/components/Typography/Typography';
import Warning from 'design-system/src/assets/icons/warning.svg?react';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';

const FormContainer = styled.div`
  margin: 1.5rem 0;
`;

const FormButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
`;

const ReviewButtonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SmallButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: var(--border-radius-medium);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
`;

const ReviewButton = styled(SmallButton)`
  color: var(--black);
  background-color: transparent;
  gap: 0.25rem;
  border: 1px solid var(--border-gray);
  transition: background-color 0.2s ease-out;
`;

const AcceptButton = styled(ReviewButton)`
  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }
`;

const RejectButton = styled(ReviewButton)<{ $selected: boolean }>`
  display: flex;
  gap: 0.5rem;
  color: var(--black);

  > svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: var(--light-yellow);
    border-color: var(--light-yellow);
  }

  ${({ $selected }) =>
    $selected &&
    `
    background-color: var(--light-yellow);
    border-color: var(--light-yellow);
  `}
`;

const ErrorMessage = styled(Typography)`
  color: var(--red);
  margin-top: 0.5rem;
`;

const AppealReviewView: React.FC = () => {
  const rejectAppealInputRef = React.useRef<HTMLTextAreaElement>(null);

  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const [rejectionFormError, setRejectionFormError] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const { appealReviewQuery, appealReviewId, onAppealReviewUpdate, updating, setUpdating } =
    useContext(AppealReviewContext);

  const navigate = useNavigate();

  const acceptAppeal = useMutation({
    mutationFn: async () => {
      const res = await client.post(`appeal-reviews/${appealReviewId}/accept`);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
      navigate('/appeal-reviews');
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
    },
  });

  const rejectAppeal = useMutation({
    mutationFn: async () => {
      const body = {
        response: rejectAppealInputRef.current?.value,
      };
      const res = await client.post(`appeal-reviews/${appealReviewId}/reject`, body);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onAppealReviewUpdate();
      navigate('/appeal-reviews');
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
    },
  });

  let body;
  if (appealReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (appealReviewQuery.error) {
    body = <PageLoadError />;
  } else if (appealReviewQuery.data) {
    const { appeal_review, patient, prescriber, drug } = appealReviewQuery.data;

    const appealReviewTitle = `${drug.name} (${patient.last_name})`;

    const activityList = [];
    if (
      appeal_review.status !== 'unreviewed' &&
      // These checks are just to satisfy typescript. If the appeal has been reviewed,
      // there should be reviewed_by and reviewed_at properties
      appeal_review.reviewed_by &&
      appeal_review.reviewed_at
    ) {
      activityList.push(
        <TimelineItem
          key={`appeal-review-action`}
          // If there's a reject_justification, that will be the last item in the timeline instead of this item
          isLastCommentInList={!!appeal_review.reject_justification ? false : true}
          author={appeal_review.reviewed_by}
          timestamp={appeal_review.reviewed_at}
          action={appeal_review.status === 'accepted' ? 'accepted appeal' : 'requested edits'}
        />,
      );
    }
    if (
      appeal_review.reject_justification &&
      // This check is just to satisfy typescript. If the appeal has been reviewed,
      // there should be a reviewed_by property
      appeal_review.reviewed_by &&
      appeal_review.reviewed_at
    ) {
      activityList.push(
        <TimelineItem
          key={`appeal-review-action-justification`}
          isLastCommentInList={true}
          author={appeal_review.reviewed_by}
          timestamp={appeal_review.reviewed_at}
          message={appeal_review.reject_justification}
        />,
      );
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" renderedAs="h2" marginBottom="1rem">
                {appealReviewTitle}
              </Typography>

              <GeneralInfoTable
                patientName={`${patient.first_name} ${patient.last_name}`}
                dob={patient.date_of_birth}
                prescriberName={prescriber.name}
                prescribedOn={drug.prescription_recieved_at}
              />

              {/* NOTE: `redrafted` appeal reviews are treated like `rejected` appeal reviews because
                        they were rejected by a user and a justification was given for the rejection. */}
              {appeal_review.status === 'unreviewed' && (
                <FormContainer>
                  <Form $hasError={rejectionFormError}>
                    {updating && (
                      <FormOverlay>
                        <FormOverlayLoadingSpinner />
                      </FormOverlay>
                    )}

                    <FormButtonContainer>
                      <ReviewButtonContainer>
                        <AcceptButton onClick={() => acceptAppeal.mutate()}>Accept</AcceptButton>
                        <RejectButton
                          $selected={isRejecting}
                          onClick={() => {
                            if (isRejecting && !updating) {
                              setIsRejecting(false);
                              setRejectionFormError(false);
                            } else {
                              setIsRejecting(true);
                            }
                          }}
                        >
                          {isRejecting && <Warning aria-hidden={true} />}
                          Request edits
                        </RejectButton>
                      </ReviewButtonContainer>
                    </FormButtonContainer>

                    {isRejecting && (
                      <>
                        <ResponseInput ref={rejectAppealInputRef} placeholder="Add a response..." defaultValue="" />
                        <ButtonRow>
                          <SmallButton
                            onClick={() => {
                              if (rejectAppealInputRef.current?.value.length!!) {
                                rejectAppeal.mutate();
                              } else {
                                setRejectionFormError(true);
                              }
                            }}
                            disabled={rejectAppeal.isPending}
                          >
                            Submit
                          </SmallButton>
                        </ButtonRow>
                      </>
                    )}
                  </Form>
                  {rejectionFormError && (
                    <ErrorMessage styledAs="bodySmallSpaceGrotesk" font={500}>
                      You must add a reason to request edits
                    </ErrorMessage>
                  )}
                </FormContainer>
              )}

              {/* This check is just to satisfy typescript. If the appeal has been reviewed,
                  there should be reviewed_by and reviewed_at properties */}
              {appeal_review.status !== 'unreviewed' && appeal_review.reviewed_by && appeal_review.reviewed_at && (
                <TimelineItemList>{activityList.map((activityItem) => activityItem)}</TimelineItemList>
              )}
            </CenteredContent>

            <PDFViewer pdfUrl={url(`/appeal-reviews/${appealReviewId}/document`)} />
          </ContentOuterWrapper>
        </TopNavAndContent>
        <Sidebar $isCollapsed={isSidebarCollapsed}>
          <SidebarTopBar />
          <DrugPanel drug={drug} />
          <PatientPanel patient={patient} />
          <PrescriberPanel prescriber={prescriber} />
        </Sidebar>
      </BodyContainer>
    );
  }

  return body;
};

export default AppealReviewView;

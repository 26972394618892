import { formatSimpleDate, formatStandardDate } from '../../utils';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';

export type GeneralInfoTableProps = {
  // First and last name
  patientName: string | null;
  // First and last name
  prescriberName: string | null;
  // Unformatted datestring
  dob: string | null;
  // Unformatted datestring
  prescribedOn: string | null;
  // Anything else you want tacked on to the bottom of the table
  extraData?: Array<{ label: string; value: string | null }>;
};

/**
 * Table with patient and prescriber info for top of detail pages (rx page, task page, review pages)
 */
export const GeneralInfoTable = ({
  patientName,
  prescriberName,
  dob,
  prescribedOn,
  extraData = [],
}: GeneralInfoTableProps) => {
  let patientValue = patientName;
  if (dob) {
    patientValue += ` (${formatStandardDate(dob)})`;
  }
  let prescriberValue = prescriberName;
  if (prescribedOn) {
    prescriberValue += ` on ${formatSimpleDate(prescribedOn)}`;
  }

  const generalData = [
    { label: 'Patient', value: patientValue },
    { label: 'Prescribed by', value: prescriberValue },
  ].concat(extraData);
  return <ExtraInfoTable data={generalData} />;
};

import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage.tsx';
import { DropdownItem } from '../Dropdown.tsx.tsx';
import { Specialty, Size, Ehr, Role, HereAs, HeardAboutFrom, Credential } from '../../types.ts';

export const requiredErrorString = 'Required';
export const requiredErrorMessage = <ErrorMessage>{requiredErrorString}</ErrorMessage>;

const specialties: Record<Specialty, string> = {
  allergy_and_immunology: 'Allergy and immunology',
  cardiology: 'Cardiology',
  dermatology: 'Dermatology',
  endocrinology: 'Endocrinology',
  gastroenterology: 'Gastroenterology',
  obstetrics_and_gynecology: 'Obstetrics and gynecology',
  hepatology: 'Hepatology',
  internal_medicine: 'Internal medicine',
  neurology: 'Neurology',
  oncology: 'Oncology',
  ophthalmology: 'Ophthalmology',
  pediatrics: 'Pediatrics',
  psychiatry: 'Psychiatry',
  pulmonology: 'Pulmonology',
  rheumatology: 'Rheumatology',
  urology: 'Urology',
  multi_specialty: 'Multi-specialty',
  none: 'None',
  other: 'Other',
};

const specialtyKeys = Object.keys(specialties) as Specialty[];

export const getSpecialtyOptions = () =>
  specialtyKeys.map((specialtyKey) => {
    const specialtyValue = specialties[specialtyKey];

    return (
      <DropdownItem key={specialtyKey} value={specialtyKey}>
        {specialtyValue}
      </DropdownItem>
    );
  });

const sizes: Record<Size, string> = {
  less_than_five: 'Less than 5 providers',
  five_to_ten: '5 - 10 providers',
  eleven_to_thirty: '11 - 30 providers',
  thirty_one_to_fifty: '31 - 50 providers',
  fifty_one_to_one_hundred: '51 - 100 providers',
  one_hundred_plus: 'More than 100 providers',
};

const sizeKeys = Object.keys(sizes) as Size[];

export const getSizeOptions = () =>
  sizeKeys.map((sizeKey) => {
    const sizeValue = sizes[sizeKey];

    return (
      <DropdownItem key={sizeKey} value={sizeKey}>
        {sizeValue}
      </DropdownItem>
    );
  });

const ehrs: Record<Ehr, string> = {
  allscripts: 'AllScripts',
  athena: 'Athena',
  cerner: 'Cerner',
  curemd: 'CureMD',
  drchrono: 'DrChrono',
  eclinicalworks: 'eClinicalworks',
  elation: 'Elation',
  ema_modmed: 'EMA (ModMed) ',
  epic: 'Epic',
  ggastro_modmed: 'gGastro (ModMed)',
  next_gen: 'Next Gen',
  nextech: 'NexTech',
  practice_fusion: 'Practice Fusion',
  other: 'Other',
  none: 'None',
};

const ehrKeys = Object.keys(ehrs) as Ehr[];

export const getEhrOptions = () =>
  ehrKeys.map((ehrKey) => {
    const ehrValue = ehrs[ehrKey];

    return (
      <DropdownItem key={ehrKey} value={ehrKey}>
        {ehrValue}
      </DropdownItem>
    );
  });

const roles: Record<Role, string> = {
  administrative_assistant: 'Administrative Assistant',
  medical_assistant: 'Medical Assistant',
  nurse: 'Nurse',
  nurse_practitioner: 'Nurse Practitioner',
  office_manager: 'Office Manager',
  physician: 'Physician',
  physician_assistant: 'Physician Assistant',
  practice_manager: 'Practice Manager',
  prior_authorization_specialist: 'Prior Authorization Specialist',
  other: 'Other',
};

const roleKeys = Object.keys(roles) as Role[];

export const getRoleOptions = () =>
  roleKeys.map((roleKey) => {
    const roleValue = roles[roleKey];

    return (
      <DropdownItem key={roleKey} value={roleKey}>
        {' '}
        {roleValue}{' '}
      </DropdownItem>
    );
  });

const credentials: Record<Credential, string> = {
  md: 'MD',
  do: 'DO',
  pa: 'PA',
  np: 'NP',
  rn: 'RN',
  other: 'Other',
  none: 'None',
};

const credentialsKeys = Object.keys(credentials) as Credential[];

export const getCredentialsOptions = () =>
  credentialsKeys.map((credentialOption) => {
    return (
      <DropdownItem key={credentialOption} value={credentialOption}>
        {' '}
        {credentials[credentialOption]}{' '}
      </DropdownItem>
    );
  });

const hereAs: Record<HereAs, string> = {
  prescriber: 'I am a prescriber',
  prescriber_delegate: 'I am a prescriber delegate',
  patient: 'I am a patient',
  pharmaceutical_company: 'I work for a pharmaceutical company',
  insurance_plan: 'I work for an insurance plan',
  pharmacy: 'I work at a pharmacy',
};

const hereAsKeys = Object.keys(hereAs) as HereAs[];

export const getHereAsOptions = () =>
  hereAsKeys.map((hereAsOption) => {
    return (
      <DropdownItem key={hereAsOption} value={hereAsOption}>
        {' '}
        {hereAs[hereAsOption]}{' '}
      </DropdownItem>
    );
  });

const heardAboutFrom: Record<HeardAboutFrom, string> = {
  my_practice: 'From someone in my practice or group',
  another_practice: 'From another provider practice',
  pharmaceutical_rep: 'From a pharmaceutical rep or employee',
  pharmacy: 'From a pharmacy',
  patient: 'From a patient',
  online: 'Online (on Facebook, LinkedIn, TikTok, etc.)',
  other: 'Other',
};

const heardAboutFromKeys = Object.keys(heardAboutFrom) as HeardAboutFrom[];

export const getHeardAboutFromOptions = () =>
  heardAboutFromKeys.map((heardAboutFromOption) => {
    return (
      <DropdownItem key={heardAboutFromOption} value={heardAboutFromOption}>
        {' '}
        {heardAboutFrom[heardAboutFromOption]}{' '}
      </DropdownItem>
    );
  });

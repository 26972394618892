import styled from 'styled-components';
import { Modal, ModalTitle } from 'design-system/src/components/Modal';

const StyledVideo = styled.video`
  min-width: 100%;
  height: auto;
  border-radius: var(--border-radius-small);
`;

export type OnboardingModalProps = {
  onClose: () => void;
};

/**
 * A modal with information to help new users nagivate the app
 */
export const OnboardingModal = ({ onClose }: OnboardingModalProps) => {
  return (
    <Modal wide={true} onClose={onClose}>
      <ModalTitle>Welcome to the new Tandem Portal</ModalTitle>
      <StyledVideo width="auto" height="auto" controls>
        <source src="https://public-demo-video.s3.us-east-1.amazonaws.com/onboarding_demo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </Modal>
  );
};

import { Typography } from '../Typography/Typography';
import { pageLevelErrorCopy } from '../../sharedCopy';

/**
 * Page-level error message for when the whole page content (minus the sidebar, topnav, page title) fails to load
 * TODO: get a real page loading error design with a branded illustration
 */
export const PageLoadError = () => {
  return <Typography>{pageLevelErrorCopy}</Typography>;
};

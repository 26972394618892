import { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage.tsx';
import type { AllFormData, SignupFormData } from '../../../types.ts';
import { FormLabel } from '../../FormLabel';
import { Input } from '../../Input';
import { Dropdown } from '../../Dropdown.tsx';
import { FormContainer } from '../FormContainer/FormContainer';
import {
  requiredErrorString,
  requiredErrorMessage,
  getRoleOptions,
  getCredentialsOptions,
  getHereAsOptions,
  getHeardAboutFromOptions,
} from '../utils.tsx';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import { ControllerWrapper } from '../ControllerWrapper.tsx';

const StyledSignupForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

// TODO(design-system): Replace with Link component
const Link = styled.a`
  color: var(--dark-gray);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid var(--medium-gray);
  padding-bottom: 2px;
`;

type SignupFormProps = {
  onContinue: (formData: SignupFormData) => void;
  onBeforeUnload: (formData: SignupFormData) => void;
  // For prefilling the form with data if the user navigates to another step and then comes back
  formData: AllFormData;
};

/**
 * Form collecting basic information for a provider to sign up.
 */
export const SignupForm = ({ onContinue, onBeforeUnload, formData }: SignupFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<SignupFormData>({ defaultValues: formData });
  const [credentialsValue, heardAboutFromValue] = useWatch({ control, name: ['credentials', 'heard_about_from'] });

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    onBeforeUnload(getValues());
    // We need to return a truthy value to get the browser to show the alert
    // that tries to persuade the user to stay and fill out the form
    return true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const subtitle = (
    <>
      Are you a patient? Ask your doctor to sign up for Tandem, or reach out to{' '}
      <Link href="mailto:support@withtandem.com">support@withtandem.com.</Link>
    </>
  );

  return (
    <FormContainer title="Sign up" subtitle={subtitle} showExtraContent={true}>
      <StyledSignupForm onSubmit={handleSubmit(onContinue)}>
        <ControllerWrapper>
          <Controller
            control={control}
            name="first_name"
            rules={{ required: true }}
            render={({ field }) => (
              <Input label="First name" autoComplete="given-name" hasError={!!errors.first_name} {...field} />
            )}
          />
          {errors.first_name && requiredErrorMessage}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            control={control}
            name="last_name"
            rules={{ required: true }}
            render={({ field }) => (
              <Input label="Last name" autoComplete="family-name" hasError={!!errors.last_name} {...field} />
            )}
          />
          {errors.last_name && requiredErrorMessage}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            control={control}
            name="email"
            rules={{
              required: { value: true, message: requiredErrorString },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email',
              },
            }}
            render={({ field }) => <Input label="Email" autoComplete="email" hasError={!!errors.email} {...field} />}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: { value: true, message: requiredErrorString },
              pattern: {
                value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                message: 'Invalid phone',
              },
            }}
            render={({ field }) => <Input label="Phone" autoComplete="tel" hasError={!!errors.phone} {...field} />}
          />
          {errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <FormLabel id="role-label">Role</FormLabel>
                <Dropdown labelId="role-label" id="role" label="Role" $hasError={!!errors.role} {...field}>
                  {getRoleOptions()}
                </Dropdown>
              </>
            )}
          />
          {errors.role && requiredErrorMessage}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            name="credentials"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <FormLabel id="credentials-label">Credentials</FormLabel>
                <Dropdown
                  labelId="credentials-label"
                  id="credentials"
                  label="Credentials"
                  $hasError={!!errors.credentials}
                  {...field}
                >
                  {getCredentialsOptions()}
                </Dropdown>
              </>
            )}
          />
          {errors.credentials && requiredErrorMessage}
        </ControllerWrapper>

        {credentialsValue && credentialsValue.includes('other') && (
          <Controller
            control={control}
            name="specify_other_credentials"
            rules={{ required: true }}
            render={({ field }) => (
              <Input label="Specify other credentials" hasError={!!errors.specify_other_credentials} {...field} />
            )}
          />
        )}

        <ControllerWrapper>
          <Controller
            name="here_as"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <FormLabel id="signing-up-as-label">I am signing up as...</FormLabel>
                <Dropdown
                  labelId="signing-up-as-label"
                  id="signing-up-as"
                  label="I am signing up as..."
                  $hasError={!!errors.here_as}
                  {...field}
                >
                  {getHereAsOptions()}
                </Dropdown>
              </>
            )}
          />
          {errors.here_as && requiredErrorMessage}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            name="heard_about_from"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <FormLabel id="heard-about-from-label">Where did you hear about Tandem?</FormLabel>
                <Dropdown
                  labelId="heard-about-from-label"
                  id="heard-about-from"
                  label="Where did you hear about Tandem?"
                  $hasError={!!errors.heard_about_from}
                  {...field}
                >
                  {getHeardAboutFromOptions()}
                </Dropdown>
              </>
            )}
          />
          {errors.heard_about_from && requiredErrorMessage}
        </ControllerWrapper>

        {heardAboutFromValue && heardAboutFromValue.includes('other') && (
          <Controller
            control={control}
            name="specify_other_heard_about_from"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                label="Specify where you heard about Tandem"
                hasError={!!errors.specify_other_heard_about_from}
                {...field}
              />
            )}
          />
        )}

        <SubmitButton type="submit">Continue</SubmitButton>
      </StyledSignupForm>
    </FormContainer>
  );
};

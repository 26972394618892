import { useQuery } from '@tanstack/react-query';
import { client, RxTableResponseRow } from '../../api';
import { formatName, formatStandardDateLocaleString } from '../../utils';
import { RxFilterValues } from '../../types';

enum DrugCategory {
  Biologic = 'biologic',
  CGRP = 'cgrp',
  ComplexTopical = 'complex_topical',
}

export const useFetchPrescriptions = (page: number, per_page: number, search: string, filterValues: RxFilterValues) => {
  const prescriber_npi = filterValues.prescriber;
  const office_name = filterValues.office;
  const drug_name = filterValues.drug;
  const biologics = filterValues.biologics;
  const awaiting_office = filterValues.awaitingOffice;
  const awaiting_patient = filterValues.awaitingPatient;
  const overall_status = filterValues.overallStatus;
  return useQuery({
    queryKey: ['prescriptions', page, search, filterValues],
    queryFn: () =>
      client
        .get('/rxs', {
          params: {
            page: page + 1,
            per_page,
            search,
            prescriber_npi,
            office_name,
            drug_name,
            drug_category: biologics ? DrugCategory.Biologic : '',
            awaiting_office,
            awaiting_patient,
            overall_status,
          },
        })
        .then((res) => {
          const formattedData = res.data.results.map((item: RxTableResponseRow) => ({
            ...item,
            prescription_recieved_at: formatStandardDateLocaleString(item.prescription_recieved_at),
            prescriber_name: formatName(item.prescriber_name),
            patient_name_and_dob: `${formatName(item.patient_name)}, ${item.dob}`,
          }));
          return {
            results: formattedData,
            total: res.data.total,
          };
        }),
  });
};

import styled from 'styled-components';

// TODO: this flow needs some logic to stop keyboard users
// and screenreaders from being able to get lost behind the overlay

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - var(--nav-sidebar-width));
  height: 100%;
  margin-left: var(--nav-sidebar-width);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Content = styled.div`
  background-color: var(--extra-light-surface);
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { client } from '../api';
import type { SignupFormData, MoreInfoFormData, AllFormData } from '../types.ts';
import { TopNavbar } from '../components/TopNavbar/TopNavbar.tsx';
import { SignupForm } from '../components/signup/SignupForm/SignupForm';
import { MoreInfoForm } from '../components/signup/MoreInfoForm/MoreInfoForm';
import { AcceptTerms } from '../components/signup/AcceptTerms/AcceptTerms';
import { SignupSuccess } from '../components/signup/SignupSuccess/SignupSuccess';

const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  min-height: 100dvh;
  height: 100%;

  background-color: var(--light-surface);
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

type SignupStep = 'signupForm' | 'moreInfo' | 'acceptTerms' | 'signupSuccess';

/**
 * The page where a user signs up for an account.
 *
 * This flow does not actually lead the user to having an account persay. Users can submit their information, and then we will internally determine if the user's practice is a good fit to partner with Tandem, in which case they'll be invited to create an actual account.
 */
const SignupPage = () => {
  const [signupStep, _setSignupStep] = useState<SignupStep>('signupForm');
  const [formData, setFormData] = useState<AllFormData | {}>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userSubmittedForm, setUserSubmittedForm] = useState(false);

  const setSignupStep = (newStep: SignupStep) => {
    // Push the current step to the window history before updating the step in state
    // so the user can navigate back to the previous step using the browser back button
    window.history.pushState({ signupStep }, '');
    _setSignupStep(newStep);
  };

  // Method for browser back button, not the back button in the form
  const onBack = (event: PopStateEvent) => {
    if ('signupStep' in event.state) {
      // If the previous state is a signup form state, set the current step to that
      setSignupStep(event.state.signupStep);
    }
    // If the previous step is not a signup form step, we've gone back to when the user
    // first landed on the page, and the browser back button will exit the app
  };

  useEffect(() => {
    // Initialize history with starting form state and add listener for back button
    window.history.replaceState({ signupStep }, '');
    window.addEventListener('popstate', onBack);

    return () => {
      window.removeEventListener('popstate', onBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once to initialize history and listeners
  }, []);

  const submit = useMutation<{}, AxiosError<{ error: string }>, AllFormData | {}>({
    mutationFn: async (formData) => {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      return client.post('/signup/submit', formData).then((res) => res.data);
    },
    onSuccess: () => {
      // We want to be sure to only show the success page if the user actually submitted the form
      // (as opposed to us auto-saving the data when they tried to navigate away)
      if (userSubmittedForm) {
        setSignupStep('signupSuccess');
        // Reset form data so if the user tries to go back, the form is now empty
        setFormData({});
        setUserSubmittedForm(false);
      }
      setIsSubmitting(false);
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
      setIsSubmitting(false);
      setUserSubmittedForm(false);
    },
  });

  const handleSubmit = (newFormData: AllFormData) => {
    const updatedFormData = { ...formData, ...newFormData };
    // Because we're saving the data on page unload, it's possible to get empty submissions
    if (JSON.stringify(updatedFormData) !== '{}') {
      submit.mutate(updatedFormData);
    }
  };

  let body;

  if (signupStep === 'moreInfo') {
    body = (
      <MoreInfoForm
        onContinue={(moreInfoFormData: MoreInfoFormData) => {
          setFormData({ ...formData, ...moreInfoFormData });
          setSignupStep('acceptTerms');
        }}
        onBeforeUnload={handleSubmit}
        onBack={() => {
          setSignupStep('signupForm');
        }}
        formData={formData}
      />
    );
  } else if (signupStep === 'acceptTerms') {
    body = (
      <AcceptTerms
        isSubmitting={isSubmitting}
        onContinue={handleSubmit}
        onBack={() => {
          setSignupStep('moreInfo');
        }}
        setUserSubmittedForm={setUserSubmittedForm}
      />
    );
  } else if (signupStep === 'signupSuccess') {
    body = <SignupSuccess />;
  } else {
    body = (
      <SignupForm
        onContinue={(signupFormData: SignupFormData) => {
          setFormData({ ...formData, ...signupFormData });
          setSignupStep('moreInfo');
        }}
        onBeforeUnload={handleSubmit}
        formData={formData}
      />
    );
  }

  return (
    <Container>
      <TopNavbar />
      <BodyContainer>{body}</BodyContainer>
    </Container>
  );
};

export default SignupPage;

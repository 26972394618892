// Most tokens are specified in the index.css file as CSS variables. However,
// CSS variables don't work inside media queries, so they're defined here as
// JS variables.
export const breakpoints = {
  mobileMax: 480,
  tabletMin: 481,
  tabletMax: 768,
  smallDesktopMin: 769,
  smallDesktopMax: 1024,
  largeDesktopMin: 1025,
  largeDesktopMax: 1200,
  extraLargeScreenMin: 1201,
};

import { Table } from '@tanstack/react-table';
import styled from 'styled-components';

import ChevronLeft from '../../assets/icons/chevronLeft.svg?react';
import ChevronRight from '../../assets/icons/chevronRight.svg?react';

import { IconButton } from '../../../../partner-portal/src/components/Button/Button';
import { Typography } from 'design-system/src/components/Typography/Typography';

export const EndRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export interface PaginationTableProps {
  total: number;
  pageIndex: number;
  perPage: number;
  goBack: () => void;
  goForward: () => void;
}

interface PaginationProps<T> {
  table: Table<T>;
  goBack: () => void;
  goForward: () => void;
}

const PaginationText = styled(Typography)`
  margin-right: 1rem;
`;

const PaginationButton = styled(IconButton)`
  margin-right: 0.5rem;

  > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

const PaginationRow = styled(EndRow)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export function Pagination<T>({ table, goBack, goForward }: PaginationProps<T>) {
  return (
    <PaginationRow>
      <PaginationText styledAs="bodySmallSpaceGrotesk">
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </PaginationText>
      <PaginationButton onClick={goBack} disabled={!table.getCanPreviousPage()}>
        <ChevronLeft title="back" />
      </PaginationButton>
      <PaginationButton onClick={goForward} disabled={!table.getCanNextPage()}>
        <ChevronRight title="forward" />
      </PaginationButton>
    </PaginationRow>
  );
}

import { client, DrugCategory } from '../../api';
import { RxFilterValues } from '../../types';

export const downloadPrescriptionsTable = async (
  page: number,
  perPage: number,
  search: string,
  filterValues: RxFilterValues,
) => {
  const office_name = filterValues.office;
  const drug_name = filterValues.drug;
  const biologics = filterValues.biologics;
  const response = await client.get('/rxs/download', {
    params: {
      page: page + 1,
      per_page: perPage,
      search,
      prescriber_npi: filterValues.prescriber,
      office_name,
      drug_name,
      drug_category: biologics ? DrugCategory.Biologic : '',
      awaiting_office: filterValues.awaitingOffice ? 'true' : 'false',
      awaiting_patient: filterValues.awaitingPatient ? 'true' : 'false',
      overall_status: filterValues.overallStatus,
    },
  });
  return response.data;
};

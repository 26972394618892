import { useQuery } from '@tanstack/react-query';
import { client } from '../../api';
import { formatDrugName, prescriberNamesToDropdownOptions, stringsToDropdownOptions } from '../../utils';
import { DropdownOption } from '../../types';

export type RxFilterOptions = {
  prescribers: DropdownOption[];
  offices: DropdownOption[];
  drugs: DropdownOption[];
};

export const useFetchRxTableFilters = () => {
  return useQuery({
    queryKey: ['rx-filter-options'],
    queryFn: () =>
      client.get('/rx-filter-options').then((res) => {
        return {
          prescribers: prescriberNamesToDropdownOptions(res.data.prescribers),
          offices: stringsToDropdownOptions(res.data.offices),
          drugs: stringsToDropdownOptions(res.data.drugs, formatDrugName),
        };
      }),
  });
};

import styled from 'styled-components';
import { focusVisibleStyles, standardTransitionStyles } from '../../sharedStyles';
import DownloadTray from '../../assets/icons/downloadTray.svg?react';

// TODO(design-system): update IconButton to be able to create anchor elements
const StyledDownloadFileButton = styled.a`
  display: block;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  border-radius: var(--border-radius-medium);
  ${standardTransitionStyles}
  color: var(--dark-gray);

  &:hover {
    background: var(--light-border-gray);
  }
  ${focusVisibleStyles}
`;

export type DownloadFileButtonProps = {
  downloadUrl: string;
  className?: string;
};

/**
 * Button for downloading a file, styled as an IconButton.
 */
export const DownloadFileButton = ({ downloadUrl, className }: DownloadFileButtonProps) => {
  return (
    <StyledDownloadFileButton href={downloadUrl} download className={className}>
      <DownloadTray title="download" />
    </StyledDownloadFileButton>
  );
};

import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { client, FileType, NewCommentFile, FormFilesDocumentTypes } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { FileUploader, UploadedFile, UploadedFileRow } from './Files';
import styled from 'styled-components';
import { TaskContext } from './contexts';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { Button } from '../Button/Button';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import {
  Form,
  FormOverlay,
  FormOverlayLoadingSpinner,
  ResponseInput,
  ButtonRow,
} from '../tableItemDetailViewComponents/FormComponents';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';

const FormContainer = styled.div`
  padding-bottom: 2rem;
`;

const TaskView: React.FC = () => {
  // Rather than using this ref and a single input, we want to switch
  // to react json schema forms to allow for more dynamic task defintions
  // Until we do that, we are using a less robust but quicker to imoplement
  // solution.
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // Files that have been uploaded as part of composing a new comment
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);
  const [duplicateFileNameUploaded, setDuplicateFileNameUploaded] = useState<string | null>(null);

  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);

  // NOTE: this logic is duplicated in NewRequestForm.tsx - please keep in sync
  const onFilesUpload = (filesToUpload: NewCommentFile[]) => {
    setDuplicateFileNameUploaded(null);
    let foundDuplicateFileName = false;

    // Error out if the user happened to choose a file with the same name (path) as one they've
    // already added. Normally it wouldn't matter, but we're using the file names as keys to track
    // the document types when sending them to the server for saving. If they upload another file
    // with the same name, they will both be uploaded with the same document type, which will
    // cause problems downstream.
    filesToUpload.map((fileToUpload) => {
      const fileNameAlreadyUploaded = newFilesUploaded.find((uploadedFile: NewCommentFile) => {
        return fileToUpload.file.name === uploadedFile.file.name;
      });
      if (fileNameAlreadyUploaded) {
        setDuplicateFileNameUploaded(fileToUpload.file.name);
        // We're using a local variable because the state won't update fast enough for us to use
        // that value
        foundDuplicateFileName = true;
        return;
      }
    });

    if (!foundDuplicateFileName) {
      setNewFilesUploaded([...newFilesUploaded, ...filesToUpload]);
    }
  };

  const onFileDelete = (filename: string, document_type: FileType) => {
    const newFilesUploadedCopy = [...newFilesUploaded];

    const fileIndex = newFilesUploadedCopy.findIndex((fileInfo) => {
      return fileInfo.file.name === filename && fileInfo.document_type === document_type;
    });

    if (fileIndex === -1) {
      toast.custom(() => <StandardErrorToast />);
      console.error("Couldn't delete file because we couldn't find it: ", filename);
    } else {
      newFilesUploadedCopy.splice(fileIndex, 1);
    }

    setNewFilesUploaded(newFilesUploadedCopy);
  };

  const submit = useMutation({
    mutationFn: async () => {
      // We need at least a message or a file to submit a new comment
      if (!inputRef.current?.value && !newFilesUploaded.length) {
        return;
      }

      const formData = new FormData();
      formData.append('message', inputRef.current?.value || '');

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};

        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      const res = await client.post(`tasks/${taskId}/submit`, formData);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
      setNewFilesUploaded([]);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
    },
  });

  let body;
  if (taskQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const { task, drug, patient, prescriber } = taskQuery.data;

    const showSidebar = drug && patient && prescriber;

    const getGeneralInfoTableData = () => {
      let patientName = null;
      let prescriberName = null;
      let dob = null;
      let prescribedOn = null;

      if (drug) {
        patientName = drug.patient_name;
        prescriberName = drug.prescriber_name;
        dob = drug.dob;
        prescribedOn = drug.prescription_recieved_at;
      } else if (task.first_name && task.last_name && task.dob) {
        // Old requests don't have rx data, but they should have these other values
        patientName = `${task.first_name} ${task.last_name}`;
        dob = task.dob || null;
      }

      return {
        patientName,
        prescriberName,
        dob,
        prescribedOn,
      };
    };

    const comments = (
      <>
        {task.comments && (
          <TimelineItemList>
            {task.comments.map((comment, index) => {
              return (
                <TimelineItem
                  key={`task-comment-${comment.id}`}
                  isLastCommentInList={index === task.comments.length - 1}
                  author={comment.direction === 'inbound' ? comment.user : 'Tandem'}
                  timestamp={comment.created_at}
                  message={comment.message}
                  attachedFilesIds={comment.attachment_document_ids}
                />
              );
            })}
          </TimelineItemList>
        )}
      </>
    );

    const form = (
      <>
        <FormContainer>
          <Form>
            {updating && (
              <FormOverlay>
                <FormOverlayLoadingSpinner />
              </FormOverlay>
            )}
            <ResponseInput ref={inputRef} placeholder="Add a response..." />
            <UploadedFileRow>
              {newFilesUploaded.map((fileInfo) => {
                return (
                  <UploadedFile
                    key={`${fileInfo.file.name}`}
                    onFileDelete={onFileDelete}
                    filename={fileInfo.file.name}
                    fileType={fileInfo.document_type}
                  />
                );
              })}
            </UploadedFileRow>
            <ButtonRow>
              <FileUploader onFilesUpload={onFilesUpload} />
              <Button onClick={() => submit.mutate()} disabled={submit.isPending}>
                Submit
              </Button>
            </ButtonRow>
          </Form>
          <ErrorMessage visible={!!duplicateFileNameUploaded}>
            {`A file with the name ${duplicateFileNameUploaded || ''} has already been added. Please change the file name and try again.`}
          </ErrorMessage>
        </FormContainer>
      </>
    );

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            noSidebar={!showSidebar}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" marginBottom="1rem">
                {task.type}
              </Typography>

              <GeneralInfoTable {...getGeneralInfoTableData()} />

              {comments}

              {form}
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {showSidebar && !isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return body;
};

export default TaskView;

import React from 'react';
import styled from 'styled-components';
import { Menu, MenuButton, MenuSection, MenuHeading, MenuItem, MenuItems } from '@headlessui/react';
import { popoverBoxshadow } from 'design-system/src/sharedStyles';

// These components don't require styling because they wrap
// some other element that's styled directly
export const PopoverMenuButton = MenuButton;
export const PopoverMenuItem = MenuItem;

export const StyledMenuItems = styled(MenuItems)`
  // TODO: extract these styles to be shared by other popover components
  box-sizing: border-box;
  z-index: 1000;
  width: 18.75rem;
  padding: 0.5rem;
  background: var(--white);
  border-radius: 0.9375rem;
  border: 1px solid var(--border-gray);
  ${popoverBoxshadow}
  // This CSS variable is defined by Headless UI
  // It specifies how far the menu appears from the button
  // that triggers it
  --anchor-gap: 0.5rem;

  // This data attribute comes from Headless UI and styles
  // the closed state of the menu so we can animate it in
  &[data-closed] {
    opacity: 0;
  }
  transition: opacity 0.2s ease-out;
`;

export const PopoverMenuSection = styled(MenuSection)`
  width: 100%;
`;

export const PopoverMenuHeading = styled(MenuHeading)`
  padding: 0.5rem 1rem;
  color: var(--dark-gray);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // TODO: replace with Small Body/Regular typography component when it's available
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.009375rem;
`;

export const PopoverMenuItemButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--black);
  background-color: transparent;
  border-radius: var(--border-radius-medium);
  border: none;
  padding: 0.5rem 1rem;
  transition:
    background 0.2s ease-out,
    box-shadow 0.2s ease-out;
  &:hover,
  &:active,
  &:focus-visible {
    background: var(--light-border-gray);
  }
  &:focus-visible {
    box-shadow:
      0px 0px 0px 1px var(--white),
      0px 0px 0px 3px var(--purple);
    // for Windows High Contrast Mode, which removes box shadows but makes all borders
    // 100% opacity black
    outline: 1px solid rgba(0, 0, 0, 0);
  }
`;

export type PopoverMenuItemsProps = { children: React.ReactNode };

export const PopoverMenuItems = (props: PopoverMenuItemsProps) => {
  const { children, ...rest } = props;

  return (
    <StyledMenuItems anchor="top start" transition={true} {...rest}>
      {children}
    </StyledMenuItems>
  );
};

export type PopoverMenuProps = { children: React.ReactNode };

export const PopoverMenu = (props: PopoverMenuProps) => {
  const { children, ...rest } = props;

  return (
    <Menu {...rest}>
      {/* This div is required by Headless UI; the Menu children need to be wrapped */}
      <div>{children}</div>
    </Menu>
  );
};

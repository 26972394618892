import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '../api';
import type { TaskSummary, TasksResponse } from '../api';
import PageContainer from '../components/PageContainer';
import TasksTable from '../components/tasks/TasksTable';
import { Outlet } from 'react-router-dom';
import { tasksQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { formatStandardDate, formatStandardDateLocaleString } from '../utils';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

const TasksTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery<TasksResponse>({
    queryKey: tasksQueryKey(),
    queryFn: () =>
      client.get('/tasks').then((res) => {
        res.data.tasks = res.data.tasks.map((item: TaskSummary) => ({
          ...item,
          task_created_at: formatStandardDateLocaleString(item.task_created_at),
          patient_dob: formatStandardDate(item.patient_dob),
          drug: {
            ...item.drug,
            // We format dates here instead of in TasksTable so that the global search function applies to the formatted data
            prescription_recieved_at: item.drug
              ? formatStandardDateLocaleString(item.drug.prescription_recieved_at)
              : null,
          },
        }));

        return res.data;
      }),
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = <PageLoadError />;
  } else if (data) {
    body = <TasksTable data={data.tasks} />;
  }

  const queryClient = useQueryClient();

  return (
    <>
      <PageContainer title="Tasks">{body}</PageContainer>
      <Outlet
        context={{
          reload: () => queryClient.resetQueries({ queryKey: tasksQueryKey() }),
        }}
      />
    </>
  );
};

export default TasksTablePage;

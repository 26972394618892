import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';

export const Sidebar = styled.div<{ $isCollapsed?: boolean }>`
  display: ${({ $isCollapsed }) => ($isCollapsed ? 'none' : 'flex')};
  flex-direction: column;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 133.333%;
  // NOTE: the sidebar was shifting in size when we were only using 'width'
  min-width: 19.75rem;
  max-width: 19.75rem;
  border-left: 1px solid var(--light-border-gray);

  > div:last-child {
    border-bottom: none;
  }
`;

export const SidebarSection = styled.div`
  border-bottom: 1px solid var(--light-border-gray);
  padding: 1.5rem;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
`;

export const SidebarHeader = styled.div`
  color: var(--black);
  font-weight: 590;
  margin-bottom: 0.5rem;
`;

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
`;

export const SidebarRowLabel = styled.div`
  color: var(--dark-gray);
  font-weight: 400;
  flex: 1;
`;

export const SidebarRowData = styled.div`
  color: var(--black);
  flex: 1;
`;

export const StyledSidebarTopBar = styled.div`
  border-bottom: 1px solid var(--light-border-gray);
  height: var(--item-view-top-nav-height);
  box-sizing: border-box;
  padding: 1rem 1.5rem;
`;

export const SidebarTopBar = () => {
  return (
    <StyledSidebarTopBar>
      <Typography styledAs="bodySmallSpaceGrotesk" weight={500}>
        Details
      </Typography>
    </StyledSidebarTopBar>
  );
};

import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuHeading,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from '../PopoverMenu';
import { FlexRow } from '../Layout';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { FileInfoResponse, fileTypes, FileType, NewCommentFile, apiHost, client } from '../../api';
import { taskFileQueryKey } from '../../queries';
import { useQuery } from '@tanstack/react-query';
import { TaskContext } from './contexts';
import Paperclip from 'design-system/src/assets/icons/paperclip.svg?react';
import Close from 'design-system/src/assets/icons/close.svg?react';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import { truncateLongText } from '../../utils';

export const UploadedFileRow = styled(FlexRow)`
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const fileDisplayStyles = `
  display: inline-flex;
  flex-direction: column;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--extra-light-surface);
  margin-right: 0.5rem;
`;

const FileDisplay = styled.div`
  ${fileDisplayStyles}
`;

const attachedFileDisplayStyles = `
  box-sizing: border-box;
  width: 168px;
  // Set a height to keep stop it from shifting if the error text
  // appears
  height: 58px;
  display: flex;
  flex-direction: row;
  margin-right: 0;
  padding: 10px;
  gap: 0.5rem;
`;

const AttachedFileDisplay = styled(FileDisplay)`
  ${attachedFileDisplayStyles}
`;

const DownloadableFileDisplay = styled(AttachedFileDisplay)<{ $downloadable: boolean }>`
  all: unset;
  ${fileDisplayStyles}
  ${attachedFileDisplayStyles}
  cursor: ${({ $downloadable }) => ($downloadable ? 'pointer' : 'auto')};
  ${standardTransitionStyles}

  &:hover {
    background-color: ${({ $downloadable }) =>
      $downloadable ? 'var(--light-border-gray)' : 'var(--extra-light-surface)'};
  }
  ${focusVisibleStyles}
`;

const AttachFileButton = styled(FileDisplay)`
  // The border is on the UploadPopoverButton, which is inside
  // this container
  border: none;
  background-color: transparent;
`;

const UploadPopoverButton = styled(PopoverMenuButton)`
  all: unset;
  box-sizing: border-box;
  width: 150px;
  border: 1px solid var(--border-gray);
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: pointer;
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
  }
  ${focusVisibleStyles}
`;

const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  // The negative margin allows us to make the button a little bigger than
  // the space allows, sitting on top of the padding and gap around it
  margin: -0.25;
  display: flex;
  justify-content: center;
  align-items: center;
  ${standardTransitionStyles}
  &:hover {
    background-color: var(--light-border-gray);
  }
  ${focusVisibleStyles}
`;

const FileIconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  color: var(--dark-gray);
`;

const HiddenInput = styled.input`
  display: none;
`;

const getFileTypeUI = (fileType: FileType | string) => {
  return fileType === 'Insurance Card Both Sides' ? 'Insurance Card' : fileType;
};

const loadingText = 'Loading...';

const getFileName = (isLoading: boolean, isError: boolean, data: FileInfoResponse | undefined) => {
  if (isLoading) {
    return loadingText;
  }
  if (isError) {
    return "File isn't available. Try again later.";
  }
  return data ? truncateLongText(data.filename, 15) : null;
};

const getFileType = (data: FileInfoResponse | undefined) => {
  if (!data) {
    return loadingText;
  }
  const fileType = data?.tags.find((tag) => tag.name === 'Document Type')?.value;
  if (!fileType || !fileTypes.includes(fileType)) {
    return '';
  }
  return getFileTypeUI(fileType);
};

interface FileUploaderProps {
  onFilesUpload: (newFiles: NewCommentFile[]) => void;
}

export const FileUploader: React.FC<FileUploaderProps> = ({ onFilesUpload }) => {
  const [uploadingFileType, setUploadingFileType] = React.useState<FileType | ''>('');
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (uploadingFileType === '' || files.length === 0) {
      return;
    }

    const filesWithType = files.map((file) => ({
      file: file,
      document_type: uploadingFileType,
    }));
    onFilesUpload(filesWithType);
    setUploadingFileType('');

    // Reset the file input value so the same file can be uploaded again if needed
    // We want to show an error message in that case instead of silently blocking the user
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (fileInputRef.current) {
      // onCancel isn't supported in react yet, but it will be soon
      // https://github.com/facebook/react/pull/27897
      fileInputRef.current.addEventListener('cancel', () => {
        // Reset the state if the user cancels out of the file selection dialog. Otherwise
        // the user won't be able to select the same file type again.
        setUploadingFileType('');
      });
    }
  }, []);

  useEffect(() => {
    if (!!uploadingFileType && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [uploadingFileType]);

  return (
    <AttachFileButton>
      <HiddenInput type="file" ref={fileInputRef} onChange={handleFileChange} multiple />

      <PopoverMenu>
        <UploadPopoverButton>Attach file</UploadPopoverButton>

        <PopoverMenuItems>
          <PopoverMenuSection>
            <PopoverMenuHeading>What type of file are you uploading?</PopoverMenuHeading>

            {fileTypes.map((fileType: FileType) => {
              return (
                <PopoverMenuItem key={`file-type-option-${fileType}`}>
                  <PopoverMenuItemButton
                    data-file-type={fileType}
                    onClick={(event) => {
                      const target = event.target as HTMLElement;
                      const fileType = target.dataset.fileType as FileType;
                      setUploadingFileType(fileType);
                    }}
                  >
                    {getFileTypeUI(fileType)}
                  </PopoverMenuItemButton>
                </PopoverMenuItem>
              );
            })}
          </PopoverMenuSection>
        </PopoverMenuItems>
      </PopoverMenu>
    </AttachFileButton>
  );
};

const FileInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.25rem;
`;

const FileName = styled(Typography).attrs({ styledAs: 'bodyExtraSmallDMSans', weight: 600 })``;

const StyledFileType = styled(Typography).attrs({ styledAs: 'bodyExtraExtraSmallDMSans' })`
  color: var(--dark-gray);
`;

interface UploadedFileProps {
  filename: string;
  fileType: FileType;
  onFileDelete: (filename: string, document_type: FileType) => void;
}

// A file that the user has selected to submit with their
// task comment
export const UploadedFile: React.FC<UploadedFileProps> = ({ filename, fileType, onFileDelete }) => {
  const filenameForUI = truncateLongText(filename);
  const fileTypeForUI = getFileTypeUI(fileType);

  return (
    <AttachedFileDisplay>
      {/* TODO: make this only appear on hover */}
      <RemoveButton onClick={() => onFileDelete(filename, fileType)}>
        <FileIconContainer>
          <Close title="remove file" />
        </FileIconContainer>
      </RemoveButton>

      <FileInfo>
        <FileName>{filenameForUI}</FileName>
        <StyledFileType>{fileTypeForUI}</StyledFileType>
      </FileInfo>
    </AttachedFileDisplay>
  );
};

// A file that is attached to a comment that has been successfully submitted
export const AttachedFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.isLoading, query.isError, query.data);
  const fileType = getFileType(query.data);

  const downloadable = !query.isLoading && !query.isError;

  return (
    <DownloadableFileDisplay
      $downloadable={downloadable}
      as={downloadable ? 'a' : 'div'}
      href={downloadable ? `${apiHost}/tasks/${taskId}/file/${fileId}/download` : undefined}
      download={downloadable}
    >
      <FileIconContainer>
        <Paperclip title="download" />
      </FileIconContainer>
      <FileInfo>
        <FileName>{filename}</FileName>
        {!query.isError && <StyledFileType>{fileType}</StyledFileType>}
      </FileInfo>
    </DownloadableFileDisplay>
  );
};

// TODO: try popover 3rd party component
import { useState, useEffect, useRef, SyntheticEvent } from 'react';
import styled from 'styled-components';
import useAutocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/useAutocomplete';
import { Typography } from '../Typography/Typography';
import ChevronDown from '../../assets/icons/chevronDown.svg?react';
import Search from '../../assets/icons/search.svg?react';
import Cancel from '../../assets/icons/cancel.svg?react';
import Checked from '../../assets/icons/checked.svg?react';
import Unchecked from '../../assets/icons/unchecked.svg?react';
import { focusVisibleStyles, popoverBoxshadow, standardTransitionStyles } from '../../sharedStyles';

const StyledMultiselectAutocomplete = styled.div`
  box-sizing: border-box;
  width: fit-content;
  position: relative;
`;

const DropdownButton = styled(Typography).attrs({ styledAs: 'bodySmallDMSans', renderedAs: 'button' })<{
  $focused: boolean;
  $hasSelection: boolean;
}>`
  box-sizing: border-box;
  white-space: nowrap;
  background-color: transparent;
  width: fit-content;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }

  ${focusVisibleStyles}

  ${({ $focused }) => ($focused ? 'background-color: var(--light-border-gray);' : '')}
  ${({ $focused }) => ($focused ? 'border-color: var(--light-border-gray);' : '')}

  ${({ $hasSelection }) => ($hasSelection ? 'border-color: var(--light-purple);' : '')}
  // !important overrides hover background style
  ${({ $hasSelection }) => ($hasSelection ? 'background-color: var(--light-purple) !important;' : '')}
`;

const NumValuesSelectedTag = styled(Typography)`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--black);
  color: var(--light-purple);
`;

const DropdownMenu = styled.div<{
  $visible: boolean;
  $verticalAlign: 'bottom' | 'top';
  $horizontalAlign: 'left' | 'right';
}>`
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  position: absolute;

  ${({ $horizontalAlign }) => $horizontalAlign}: 0;
  // the height of the button should be 32px and the distance between the button and menu should be 6px
  ${({ $verticalAlign }) => ($verticalAlign === 'bottom' ? 'transform: translateY(6px);' : 'bottom: 38px;')}

  box-sizing: border-box;
  width: 360px;
  padding: 12px 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  ${popoverBoxshadow}
  z-index: 1;
`;

const InputAndOptions = styled.div`
  box-sizing: border-box;
  width: 100%;
  // -1px to account for the 1px margin on TextField
  padding: 0 11px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextFieldWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  left: 8px;
`;

const CancelButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  padding: 8px;
  right: 0;

  ${focusVisibleStyles}
`;

const TextField = styled.input`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  // the horizontal padding leaves space for the icons
  padding: 7px 30px;
  align-items: center;
  border-radius: var(--border-radius-medium);
  // using box-shadow instead of border so it doesn't "jump" when we bump
  // the border width on focus
  box-shadow: 0 0 0 1px var(--border-gray);
  border: none;
  transition: box-shadow 0.2s ease-out;

  &:focus-visible {
    // transparent outline for windows high contrast mode
    outline: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 2px var(--purple);
  }
`;

const DropdownList = styled.ul`
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-bottom: 0;
`;

const DropdownOption = styled(Typography)`
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 8px 0;
  // for the focus outline
  border-radius: var(--border-radius-small);

  ${focusVisibleStyles}

  > svg {
    color: var(--purple);
  }
`;

const NoResults = styled(Typography)`
  padding: 8px 0;
`;

const DropdownFooter = styled.div`
  border-top: 1px solid var(--border-gray);
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px 4px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const ClearButton = styled(Typography).attrs({ styledAs: 'bodyExtraSmallDMSans', renderedAs: 'button', weight: 500 })`
  all: unset;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-medium);
  background: var(--light-border-gray);
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: var(--border-gray);
  }

  ${focusVisibleStyles}

  &:disabled {
    cursor: auto;
    color: var(--disabled-gray);
    background: var(--light-border-gray);
  }
`;

export type MultiselectAutocompleteOption = {
  value: string;
  description: string;
};

export type MultiselectAutocompleteOnChange = (
  event: SyntheticEvent<Element, Event>,
  value: MultiselectAutocompleteOption[],
  // Added for completeness to match MUI's api
  reason: AutocompleteChangeReason,
  // Added for completeness to match MUI's api
  details?: AutocompleteChangeDetails<MultiselectAutocompleteOption> | undefined,
) => void;

export type MultiselectAutocompleteProps = {
  options: MultiselectAutocompleteOption[];
  label: string;
  onChange: MultiselectAutocompleteOnChange;
  className?: string;
  initialValue?: MultiselectAutocompleteOption[];
  horizontalAlign?: 'left' | 'right';
  verticalAlign?: 'bottom' | 'top';
};

/**
 * Multiselect component with autocomplete input field.
 */
export const MultiselectAutocomplete = ({
  options,
  label,
  onChange,
  className,
  initialValue = [],
  horizontalAlign = 'left',
  verticalAlign = 'bottom',
}: MultiselectAutocompleteProps) => {
  // We have to manually control the visibility of the dropdown because the input field,
  // which is used as the trigger in the MUI component, is inside the hidden area with
  // the dropdown options
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // Tracking the input value ourselves allows us to clear the input
  const [inputValue, setInputValue] = useState('');
  const triggerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    getClearProps,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'multiselect-autocomplete',
    defaultValue: initialValue,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
    multiple: true,
    options,
    getOptionLabel: (option: MultiselectAutocompleteOption) => option.description,
    disableCloseOnSelect: true,
    // Forces the MUI dropdown to always be open because we have our own dropdown logic.
    // If `open` were not set to `true`, the user would have to open the dropdown twice
    open: true,
    // Allows keyboard users to tab from the input field to the options list without
    // the search input being cleared
    clearOnBlur: false,
    onChange,
    isOptionEqualToValue: (option: MultiselectAutocompleteOption, value: MultiselectAutocompleteOption) => {
      return option.value === value.value;
    },
  });

  // TODO: set focus back on the trigger button using triggerRef.current after automatically closing the menu
  const closeDropdownOnEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setDropdownVisible(false);
    }
  };
  const closeDropdownOnOutsideClick = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdownOnOutsideClick);
    document.addEventListener('keyup', closeDropdownOnEsc);

    return () => {
      document.removeEventListener('mousedown', closeDropdownOnOutsideClick);
      document.removeEventListener('keyup', closeDropdownOnEsc);
    };
  }, []);

  return (
    <StyledMultiselectAutocomplete ref={wrapperRef} className={className} {...getRootProps()}>
      <DropdownButton
        {...getInputLabelProps()}
        aria-expanded={dropdownVisible}
        $focused={focused || dropdownVisible}
        $hasSelection={value.length > 0}
        weight={value.length > 0 ? 500 : undefined}
        onClick={() => {
          setDropdownVisible(!dropdownVisible);
        }}
        ref={triggerRef}
      >
        {value.length === 0 && label}
        {value.length === 1 && value[0].description}
        {value.length > 1 && (
          <>
            {label}
            <NumValuesSelectedTag styledAs="bodyExtraExtraSmallDMSans" renderedAs={'span'} weight={600}>
              {value.length}
            </NumValuesSelectedTag>
          </>
        )}
        <ChevronDown aria-hidden={true} />
      </DropdownButton>

      <DropdownMenu $visible={dropdownVisible} $verticalAlign={verticalAlign} $horizontalAlign={horizontalAlign}>
        <InputAndOptions>
          <TextFieldWrapper ref={setAnchorEl}>
            <SearchIcon aria-hidden={true} />

            {inputValue.length ? (
              <CancelButton onClick={() => setInputValue('')}>
                <Cancel title="clear search text" />
              </CancelButton>
            ) : null}

            <TextField {...getInputProps()} placeholder="Search" />
          </TextFieldWrapper>

          {groupedOptions.length > 0 ? (
            <DropdownList {...getListboxProps()}>
              {groupedOptions.map((option, index: number) => {
                option = option as MultiselectAutocompleteOption;
                const { ...optionProps } = getOptionProps({ option, index });
                const isSelected = value.find(
                  // @ts-expect-error union type with AutocompleteGroupedOption<MultiselectAutocompleteOption> isn't working
                  (valueItem) => valueItem.description === option.description && valueItem.value === option.value,
                );
                return (
                  <DropdownOption
                    styledAs="bodySmallDMSans"
                    renderedAs="li"
                    {...optionProps}
                    tabIndex={0}
                    onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => {
                      if (event.key === ' ' || event.key === 'Enter') {
                        event.preventDefault();
                        // We're reusing the mouse onClick event on keyboard interaction because
                        // for some reason this functionality isn't added automatically
                        // @ts-expect-error Argument of type 'KeyboardEvent<HTMLLIElement>' is not assignable to parameter of type 'MouseEvent<HTMLLIElement, MouseEvent>'.
                        optionProps.onClick(event);
                      }
                    }}
                  >
                    {isSelected ? <Checked /> : <Unchecked />}
                    <Typography styledAs="bodySmallDMSans">{option.description}</Typography>
                  </DropdownOption>
                );
              })}
            </DropdownList>
          ) : (
            <NoResults styledAs="bodySmallDMSans">No results</NoResults>
          )}
        </InputAndOptions>

        <DropdownFooter>
          <ClearButton {...getClearProps()} tabIndex={0} disabled={!value.length}>
            Clear
          </ClearButton>
        </DropdownFooter>
      </DropdownMenu>
    </StyledMultiselectAutocomplete>
  );
};

import styled from 'styled-components';
import { Modal, ModalTitle } from 'design-system/src/components/Modal';
import { DownloadFileButton } from '../DownloadFileButton/DownloadFileButton';

const DownloadButtonContainer = styled(DownloadFileButton)`
  margin-bottom: 4px;
`;

const Iframe = styled.iframe`
  flex: 1;
  height: 100%;
  border: 1px solid var(--border-gray);
`;

const FilePreviewModal: React.FC<{
  fileHeader?: string;
  fileUrl: string;
  downloadUrl?: string;
  onClose: () => void;
}> = ({ fileHeader, fileUrl, downloadUrl, onClose }) => {
  return (
    <Modal onClose={onClose} fullHeight={true}>
      <ModalTitle>{fileHeader}</ModalTitle>

      {downloadUrl && <DownloadButtonContainer downloadUrl={downloadUrl} />}

      <Iframe src={fileUrl} title="File Preview" />
    </Modal>
  );
};

export default FilePreviewModal;

import moment from 'moment';
import { DropdownOption, NameObject, PrescriberNameObject } from './types';
import { PharmacyAddress } from './api';
import { MULTI_SELECT_DELINEATOR } from './constants';

/**
 * Formats a datestring but does not convert it to the user's timezone. Good for birth dates.
 */
export const formatStandardDate = (dateString: string) => {
  return moment(dateString).format('MM/DD/YYYY');
};

/**
 * Formats a datestring into a simple date format (M/D/YY).
 * TODO: add better error handling -- if the date string isn't parsed correctly,
 * returns "Invalid date".
 */
export const formatSimpleDate = (dateString: string) => {
  return moment(dateString).format('M/D/YY');
};

/**
 * Formats a datestring and also converts it to the user's timezone. Do not use for birth dates.
 */
export const formatStandardDateLocaleString = (dateString: string) => {
  return moment(new Date(dateString).toLocaleDateString()).format('MM/DD/YYYY');
};

/**
 * Office names could be addresses, so we want to make sure anything that's an address is placed after actual office names
 * An address starting with a number is our approximation of identifying an address
 */
export const sortOfficeNames = (officeNameA: string, officeNameB: string): number => {
  const startsWithNumber = (str: string) => /\d/.test(str[0]);

  // If `a` starts with a number and `b` does not, `a` goes after `b`
  if (startsWithNumber(officeNameA) && !startsWithNumber(officeNameB)) return 1;

  // If `b` starts with a number and `a` does not, `b` goes after `a`
  if (!startsWithNumber(officeNameA) && startsWithNumber(officeNameB)) return -1;

  // Otherwise, sort alphabetically
  return officeNameA.localeCompare(officeNameB);
};

export const truncateLongText = (
  text: string,
  maxNumLength: number | undefined = 14,
  numCharsOnEnd: number | undefined = 4,
) => {
  if (text.length > maxNumLength) {
    return (
      <span
        title={text}
      >{`${text.slice(0, maxNumLength - numCharsOnEnd)}...${numCharsOnEnd > 0 ? text.slice(-numCharsOnEnd) : ''}`}</span>
    );
  }
  return text;
};

export function stringsToDropdownOptions(options: string[], formatFn?: (option: string) => string) {
  return options.map((option) => ({ value: option, description: formatFn ? formatFn(option) : option }));
}

const titleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatDrugName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const formattedName = (name: NameObject) => {
  return `${titleCase(name.first_name)} ${titleCase(name.last_name)}`;
};

export const namesToDropdownOptions = (names: NameObject[]) => {
  return names.map((item) => {
    return {
      value: `${item.first_name}+${item.last_name}`,
      description: formattedName(item),
    } as DropdownOption;
  });
};

export const prescriberNamesToDropdownOptions = (names: PrescriberNameObject[]) => {
  return names.map((item) => {
    return {
      value: item.npi,
      description: item.name,
    } as DropdownOption;
  });
};

export const parseNamesFromDropdownValue = (optionValue: string) => {
  if (!optionValue) {
    return null;
  }
  const [first_name, last_name] = optionValue.split('+');
  return { first_name, last_name };
};

export const getDropdownInitialValueFromSearchParams = (fullString?: string) =>
  !fullString
    ? []
    : fullString.split(MULTI_SELECT_DELINEATOR).map((substring) => {
        return {
          value: substring,
          description: substring.replace('+', ' '),
        };
      });

export const getPrescriberDropdownInitialValueFromSearchParams = (
  fullString: string = '',
  optionsList: DropdownOption[],
) =>
  !fullString
    ? []
    : fullString.split(MULTI_SELECT_DELINEATOR).map((substring) => {
        return {
          value: substring,
          description: optionsList.find((option) => option.value === substring)?.description || '',
        };
      });

export const dedupeAndSortOptions = (sortFn: (a: string, b: string) => number, options: DropdownOption[]) => {
  const dedupedOptions = options.filter(
    (option, index, self) => index === self.findIndex((o) => o.description === option.description),
  );
  const sortedOptions: DropdownOption[] = [];
  const sortedDescriptions = dedupedOptions.map((option) => option.description).sort(sortFn);
  const descriptionsToOptionsMap = new Map(dedupedOptions.map((option) => [option.description, option]));
  sortedDescriptions.forEach((description) => {
    const option = descriptionsToOptionsMap.get(description);
    if (option) {
      sortedOptions.push(option);
    }
  });
  return sortedOptions;
};

export const getAddressString = (address: PharmacyAddress) => {
  return [address.name, address.line1, address.line2, `${address.city}, ${address.state} ${address.postal_code}`]
    .filter(Boolean)
    .join(', ');
};

export const formatName = (name: string) => {
  const parts = name.toLowerCase().split(' ');
  if (parts.length <= 2) {
    return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }
  const first = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
  const last = parts[parts.length - 1].charAt(0).toUpperCase() + parts[parts.length - 1].slice(1);
  const middle = parts
    .slice(1, -1)
    .map((part) => part.charAt(0).toUpperCase().concat('.'))
    .join(' ');
  return `${first} ${middle} ${last}`;
};

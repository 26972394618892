import styled from 'styled-components';
import { MultiselectAutocomplete } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import type {
  MultiselectAutocompleteOption,
  MultiselectAutocompleteProps,
} from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import Typeahead, { TypeaheadOption } from '../Typeahead';
import { TextField } from '@mui/material';
import { dedupeAndSortOptions } from '../../utils';
import { DropdownOption } from '../../types';

const StyledTableDropdownFilter = styled.div`
  width: 12rem;
`;

const StyledTextfield = styled(TextField)`
  > .MuiFormLabel-root {
    // This styles the placeholder label text
    font-size: 0.9rem;
    font-style: normal;
    line-height: 1rem;
    color: var(--black);
  }

  > .MuiInputBase-root {
    padding: 5px 60px 5px 9px !important;
    border-radius: 0.375rem;

    // This styles the selected option text
    color: var(--black);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 590;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .MuiAutocomplete-clearIndicator {
    // Always show clear icon
    visibility: visible;
  }
`;

function sortOptionsAlphabetically(a: string, b: string) {
  return a.localeCompare(b);
}

export type TableMultiselectFilterProps = MultiselectAutocompleteProps & {
  // SortFn is applied to the option descriptions
  sortFn?: (a: string, b: string) => number;
};

/**
 * A multi-select dropdown filter for use in tables
 */
export const TableMultiselectFilter = ({
  options,
  sortFn = sortOptionsAlphabetically,
  ...rest
}: TableMultiselectFilterProps) => {
  return (
    <MultiselectAutocomplete
      options={dedupeAndSortOptions(sortFn, options) as MultiselectAutocompleteOption[]}
      {...rest}
    />
  );
};

export type TableDropdownFilterProps = {
  // List of strings to display in the dropdown. Can include duplicates.
  // The component will handle transforming them into option objects.
  options: Array<DropdownOption>;
  label: string;
  onChange: (value: string) => void;
  className?: string;
  // SortFn is applied to the option descriptions
  sortFn?: (a: string, b: string) => number;
  initialValue?: string;
};

/**
 * A dropdown filter for use in tables
 */
export const TableDropdownFilter = ({
  options,
  label,
  onChange,
  className,
  sortFn = sortOptionsAlphabetically,
  initialValue = '',
}: TableDropdownFilterProps) => {
  return (
    <StyledTableDropdownFilter className={className}>
      <Typeahead
        options={dedupeAndSortOptions(sortFn, options) as TypeaheadOption[]}
        label={label}
        onChange={onChange}
        value={initialValue}
        textfieldComponent={StyledTextfield}
      />
    </StyledTableDropdownFilter>
  );
};
